<template>
    <div class="card">
        <div class="card-body">
            <div v-if="loading" class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i></div>
            <template v-else>
                <div v-if="!box" class="alert alert-info text-center">Отсканируйте ШК гастрика</div>
                <div v-else-if="!weight" class="alert alert-info text-center">
                    <div class="mb-2">Гастрик <b style="font-size: 22px;">{{box.name}}</b></div>
                    <div class="mb-2">Тип: <b>{{box.type.name}}</b></div>
                    <div class="mb-2">Текущий вес <b style="font-size: 22px;">{{!!box.sole_weight ? parseFloat(box.sole_weight / 1000).toFixed(3) + ' кг' : 'Не установлен'}}</b></div>
                    <div class="mb-2">Отсканируйте ШК весов</div>
                    <div><button class="btn" @click="cancel()">Отмена</button></div>
                </div>
                <div v-else class="alert alert-info text-center">
                    <div class="mb-2">Назначить массу <b style="font-size: 22px;">{{(weight/1000).toFixed(3)}} кг</b></div>
                    <div class="mb-2">для гастрика <b style="font-size: 22px;">{{box.name}}</b></div>
                    <div>
                        <div class="pull-left"><button @click="cancel()" class="btn btn-danger">Отмена</button></div>
                        <div class="text-right"><button @click="saveBox()" class="btn btn-success">ОК</button></div>
                    </div>
                </div>
            </template>
            <div class="text-left"><button @click="$router.go(-1)" class="btn btn-danger"><i class="fas fa-long-arrow-alt-left"></i> Назад</button></div>
        </div>
    </div>
</template>

<script>
    import Barcode from "../../utils/Barcode";
    import {BOX_ENDPOINT, WEIGHING_SCALES_ENDPOINT} from "../../utils/endpoints";

    const BOX_BARCODE_PREFIX = Barcode.gastronormContainerPrefix();
    const SCALES_BARCODE_PREFIX = Barcode.weighingScalesPrefix();

    const WITH = [
        'type'
    ];

    export default {
        name: "Assignment",
        data() {
            return {
                loading: false,
                box: null,
                weight: null,
            };
        },
        methods: {
            onBarcodeScanned(data) {
                if (!(!!this.box) && data.barcode.startsWith(BOX_BARCODE_PREFIX)) {
                    this.getBox(data.barcode);
                    return;
                }

                if (!!this.box && data.barcode.startsWith(SCALES_BARCODE_PREFIX)) {
                    this.getWeight(data.barcode);
                    return;
                }

                this.$toast.error('Неверный ШК');
            },
            getBox(barcode) {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$http.post(BOX_ENDPOINT + '/first_or_create', {
                    name: barcode,
                    type_code: 'gastronorm',

                    with: WITH,
                }, {
                    params: {without_loading: true}
                }).then(response => {
                   this.box = response.data;
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            getWeight(barcode) {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$http.get(WEIGHING_SCALES_ENDPOINT + '/weight', {
                    params: {
                        code: barcode
                    }
                }).then(response => {
                    if (!response.data.isStable) {
                        this.$toast.error('Весы не стабильны. Попробуйте еще раз');
                    } else {
                        this.weight = response.data.weight;
                    }
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            saveBox() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$http.put(BOX_ENDPOINT + '/update', {
                    id: this.box.id,
                    sole_weight: this.weight
                }, {
                    params: {
                        without_loading: true,
                        with: WITH,
                    }
                }).then(response => {
                    this.$toast.success('Успешно');
                    this.cancel();
                }).catch(response => this.$root.responseError(response, 'Ошибка!')).finally(() => {
                    this.loading = false;
                })
            },
            cancel() {
                this.box = null;
                this.weight = null;
            }
        },
        mounted() {
            this.$root.listenToBarcodeScanning(this);
        }
    }
</script>

<style scoped>

</style>
